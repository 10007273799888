module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Epoch Image","short_name":"Epoch","description":"Cutting Edge Digital Storytelling","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/icon.png","icons":[{"src":"/favicons/icon-192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a306d4234e6aaa68251f25328ac84ca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
